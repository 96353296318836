import Layout from "../../../components/Layout"
import "./tnpscIndex.css"
import Select from "react-select"
import React from "react"
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Grid,
  Image,
  Input,
  Label,
  Text,
} from "theme-ui"
import SEO from "../../../components/SEO"
import slider from "../../../../images/tnpscSlider.jpg"
import mobileSlider from "../../../../images/tnpscMobile.jpg"
import ContactSection from "../../../components/Home/ContactSection"
import { useMediaQuery } from "usehooks-ts"

export const CustomHeadingText = ({ label }: { label: string }) => {
  return (
    <Text
      sx={{ fontSize: ["16px", "24px"], fontWeight: "800", color: "black" }}
    >
      {label}
    </Text>
  )
}
export const CustomContentText = ({ label }: { label: string }) => {
  return (
    <Text mt={14} sx={{ fontSize: [0, "16px"], color: "black" }}>
      {label}
    </Text>
  )
}

const options = [
  { value: "UPSC", label: "UPSC" },
  { value: "TNPSC", label: "TNPSC" },
  { value: "SSC", label: "SSC" },
]
const centerOptions = [
  { value: "Guindy", label: "Guindy" },
  { value: "Anna Nagar", label: "Anna Nagar" },
  { value: "chetpet", label: "chetpet" },
]

const TnpscPage = ({ data }) => {
  const mobile = useMediaQuery("(max-width: 525px)")
  const tablet = useMediaQuery("(max-width: 768px)")
  const customStyles = {
    control: provided => ({
      ...provided,
      backgroundColor: "#D9D9D9",
      maxWidth: tablet ? "100%" : "300px",
    }),
  }

  const handleSubmit = e => {
    e.preventDefault()
  }

  return (
    <Box>
      <SEO title="TNPSC" />
      <Layout>
        {mobile ? (
          <Image width="100%" src={mobileSlider} alt="tnpscLogo" />
        ) : (
          <Image width="100%" src={slider} alt="tnpscLogo" />
        )}
        <Box
          sx={{
            width: "100%",
            background: "#850100",
            padding: "4%",
            marginTop: "50px",
          }}
        >
          <Box
            sx={{
              margin: "auto",
              width: "90%",
            }}
          >
            <Box>
              <Text
                color="white"
                sx={{ fontSize: ["20px", "32px"], marginTop: "20px" }}
              >
                Enroll for TNPSC
              </Text>
              <Text
                color="white"
                sx={{
                  fontSize: ["12px", "14px"],
                  marginTop: "0px",
                  marginBottom: "30px",
                }}
              >
                Kindly fill in your details
              </Text>
            </Box>
            <form onSubmit={handleSubmit}>
              <Grid columns={[1, 1, 2, 5]}>
                <Box>
                  <Input
                    sx={{
                      background: "#D9D9D9",
                      maxWidth: ["100%", "100%", "300px"],
                    }}
                    type="text"
                    placeholder="Name"
                    required
                  />
                </Box>
                <Input
                  sx={{
                    background: "#D9D9D9",
                    maxWidth: ["100%", "100%", "300px"],
                  }}
                  type="email"
                  placeholder="Email ID"
                  required
                />
                <Input
                  sx={{
                    background: "#D9D9D9",
                    maxWidth: ["100%", "100%", "300px"],
                  }}
                  type="number"
                  placeholder="Mobile No"
                  required
                />
                <Select
                  styles={customStyles}
                  placeholder="courses"
                  options={options}
                />
                <Select
                  styles={customStyles}
                  placeholder="centers"
                  options={centerOptions}
                />
              </Grid>
              <Grid mt={"30px"} columns={[1, 1, 2, 3]}>
                <Box>
                  <Input
                    sx={{
                      background: "#D9D9D9",
                      maxWidth: ["100%", "100%", "100%", "680px"],
                      height: "40px",
                    }}
                    type="text"
                    placeholder="query"
                    required
                  />
                  <Flex mt={"5px"} sx={{ gap: 0 }}>
                    <input
                      style={{
                        marginTop: "5px",
                        cursor: "pointer",
                      }}
                      type="checkbox"
                    />

                    <Text
                      sx={{
                        fontSize: "10px",
                        color: "white",
                        marginTop: "5px",
                      }}
                    >
                      I would like to receive updates from sarkar IAS Academy
                    </Text>
                  </Flex>
                </Box>

                <Button
                  mb={["30px", null]}
                  sx={{
                    height: "40px",
                    background: "var(--kakhi, #D6AF5C)",
                    color: "#9D1210",
                    maxWidth: ["100%", "100%", "100%", "680px"],
                    cursor: "pointer",
                    fontWeight:"700"
                  }}
                  type="submit"
                >
                  Submit
                </Button>
              </Grid>
            </form>
          </Box>
        </Box>
        <Box mt={"100px"}>
          <table style={{ borderCollapse: "collapse", width: "100%" }}>
            <thead style={{ border: "1px solid black" }}>
              <tr
                style={{
                  border: "1px solid  black",
                  background: "#9D1210",
                  color: "white",
                }}
              >
                <th
                  style={{
                    border: "1px solid black",
                    padding: mobile ? "8px" : "14px",
                    textAlign: "left",
                  }}
                >
                  <Text sx={{ fontSize: [0, 1] }}>Center</Text>
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    padding: mobile ? "8px" : "14px",
                    textAlign: "left",
                  }}
                >
                  <Text sx={{ fontSize: [0, 1] }}> Batch</Text>
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    padding: mobile ? "8px" : "14px",
                    textAlign: "left",
                  }}
                >
                  <Text sx={{ fontSize: [0, 1] }}> Starts on</Text>
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    padding: mobile ? "8px" : "14px",
                    textAlign: "left",
                  }}
                >
                  <Text sx={{ fontSize: [0, 1] }}>Fees</Text>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ border: "1px solid black" }}>
                <td
                  style={{
                    border: "1px solid black",
                    padding: mobile ? "10px" : "18px",
                    textAlign: "left",
                    fontWeight: "600",
                  }}
                >
                  <Text sx={{ fontSize: [0, 1] }}> Chennai - Anna Nagar</Text>
                </td>
                <td
                  style={{
                    border: "1px solid black",
                    padding: mobile ? "10px" : "18px",
                    textAlign: "left",
                    fontWeight: "600",
                  }}
                ></td>
                <td
                  style={{
                    border: "1px solid black",
                    padding: mobile ? "10px" : "18px",
                    textAlign: "left",
                    fontWeight: "600",
                  }}
                >
                  <Text sx={{ fontSize: [0, 1] }}> 3RD WEEK OF DECEMBER</Text>
                </td>
                <td
                  style={{
                    border: "1px solid black",
                    padding: mobile ? "10px" : "18px",
                    textAlign: "left",
                    fontWeight: "600",
                  }}
                ></td>
              </tr>
              <tr style={{ border: "1px solid black" }}>
                <td
                  style={{
                    border: "1px solid black",
                    padding: mobile ? "10px" : "18px",
                    textAlign: "left",
                    fontWeight: "600",
                  }}
                >
                  <Text sx={{ fontSize: [0, 1] }}> Chennai - Mangadu</Text>
                </td>
                <td
                  style={{
                    border: "1px solid black",
                    padding: mobile ? "10px" : "18px",
                    textAlign: "left",
                    fontWeight: "600",
                  }}
                ></td>
                <td
                  style={{
                    border: "1px solid black",
                    padding: mobile ? "10px" : "18px",
                    textAlign: "left",
                    fontWeight: "600",
                  }}
                >
                  <Text sx={{ fontSize: [0, 1] }}> 3RD WEEK OF DECEMBER</Text>
                </td>
                <td
                  style={{
                    border: "1px solid black",
                    padding: mobile ? "10px" : "18px",
                    textAlign: "left",
                    fontWeight: "600",
                  }}
                ></td>
              </tr>
            </tbody>
          </table>
        </Box>
        <Box ml={[null, 60, 60, 40]} mt={"30px"}>
          <Grid sx={{ placeItems: "center" }} columns={[1, 1, 2, 2]}>
            <Box mt={"40px"}>
              <Text mb={"10px"} sx={{ fontWeight: "800" }}>
                GROUP-I
              </Text>
              <Box
                sx={{
                  width: ["300px", "90%", "90%", "400px", "500px"],
                  border: "1px solid black",
                }}
              >
                <Box
                  sx={{
                    height: "100px",
                    background: "#9D1210",
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Text
                    sx={{
                      color: "white",
                      fontWeight: "800",
                      fontSize: [2, "16px"],
                    }}
                  >
                    DC, DSP, Others
                  </Text>
                </Box>
                <Box my={"30px"} mx={"30px"}>
                  <Grid columns={2}>
                    <Box>
                      <Text
                        sx={{ fontWeight: "700", fontSize: ["14px", "16px"] }}
                      >
                        Eligibility
                      </Text>
                      <Text
                        sx={{ fontWeight: "700", fontSize: ["14px", "16px"] }}
                        mt={"20px"}
                      >
                        Age
                      </Text>
                      <Text
                        sx={{ fontWeight: "700", fontSize: ["14px", "16px"] }}
                        mt={"20px"}
                      >
                        Minimum age
                      </Text>
                      <Text sx={{ fontSize: 1 }}>21 years</Text>
                      <Text
                        sx={{ fontWeight: "700", fontSize: ["14px", "16px"] }}
                        mt={"20px"}
                      >
                        Maximum Age (OC) :
                      </Text>
                      <Text sx={{ fontSize: 1 }}>
                        32 years , 33 years (Law Degree)
                      </Text>
                      <Text
                        sx={{ fontWeight: "700", fontSize: ["14px", "16px"] }}
                        mt={"20px"}
                      >
                        Maximum Age (Others) :
                      </Text>
                      <Text sx={{ fontSize: 1 }}>
                        37 years, 38 years (Law Degree)
                      </Text>
                      <Text
                        sx={{ fontWeight: "700", fontSize: ["14px", "16px"] }}
                        mt={"20px"}
                      >
                        Qualification
                      </Text>
                      <Text sx={{ fontSize: 1 }}>UG in any Degree</Text>
                      <Text
                        sx={{ fontWeight: "700", fontSize: ["14px", "16px"] }}
                        mt={"20px"}
                      >
                        Exam Pattern
                      </Text>
                      <Text
                        sx={{ fontWeight: "700", fontSize: ["14px", "16px"] }}
                      >
                        Stage I : (Preliminary – Objective Type)
                      </Text>
                      <Text sx={{ fontSize: 1 }}>
                        Subject : General Studies(175)+ Mental Ability(25)
                      </Text>
                      <Text sx={{ fontSize: 1 }}>Questions : 200</Text>
                      <Text sx={{ fontSize: 1 }}>Marks : 300 (3 hours)</Text>
                    </Box>
                    <Box>
                      <Text
                        sx={{ fontWeight: "700", fontSize: ["14px", "16px"] }}
                      >
                        Stage II (Mains - Descriptive)
                      </Text>
                      <Text sx={{ fontSize: 1 }}>
                        Subject : Paper I (Tamil Eligibility Test)
                      </Text>
                      <Text sx={{ fontSize: 1 }}>Marks : 100</Text>
                      <Text sx={{ fontSize: 1 }}>Time : 3 hours (each)</Text>
                      <Text
                        mt={"20px"}
                        sx={{ fontWeight: "700", fontSize: ["14px", "16px"] }}
                      >
                        Subject : Paper II,III,IV
                      </Text>
                      <Text sx={{ fontSize: 1 }}>Marks : 750 (each 250)</Text>
                      <Text sx={{ fontSize: 1 }}>Time : 3 hours (each)</Text>
                      <Text
                        mt={"20px"}
                        sx={{ fontWeight: "700", fontSize: ["14px", "16px"] }}
                      >
                        Stage III (Interview)
                      </Text>
                      <Text sx={{ fontSize: 1 }}>
                        Subject : Interview and Records
                      </Text>
                      <Text sx={{ fontSize: 1 }}>Marks : 100</Text>
                    </Box>
                  </Grid>
                </Box>
              </Box>
            </Box>

            <Box mt={["40px", null]}>
              <Text mb={"10px"} sx={{ fontWeight: "800" }}>
                GROUP II/IIA
              </Text>
              <Box
                sx={{
                  width: ["300px", "90%", "90%", "400px", "500px"],
                  border: "1px solid black",
                }}
              >
                <Box
                  sx={{
                    height: "100px",
                    background: "#9D1210",
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Text sx={{ color: "white", fontWeight: "800" }}>
                    Deputy State Tax Officer
                  </Text>
                </Box>
                <Box my={"30px"} mb={"30px"} mx={"30px"}>
                  <Grid columns={2}>
                    <Box>
                      <Text
                        sx={{ fontWeight: "700", fontSize: ["14px", "16px"] }}
                      >
                        Eligibility
                      </Text>
                      <Text
                        sx={{ fontWeight: "700", fontSize: ["14px", "16px"] }}
                        mt={"20px"}
                      >
                        Age
                      </Text>
                      <Text
                        sx={{ fontWeight: "700", fontSize: ["14px", "16px"] }}
                        mt={"20px"}
                      >
                        Minimum age
                      </Text>
                      <Text sx={{ fontSize: 1 }}>18/20 Years</Text>
                      <Text
                        sx={{ fontWeight: "700", fontSize: ["14px", "16px"] }}
                        mt={"20px"}
                      >
                        Maximum Age (OC) :
                      </Text>
                      <Text sx={{ fontSize: 1 }}>30 Years</Text>
                      <Text
                        sx={{ fontWeight: "700", fontSize: ["14px", "16px"] }}
                        mt={"20px"}
                      >
                        Maximum Age (Others) :
                      </Text>
                      <Text sx={{ fontSize: 1 }}>No maximum age limit</Text>
                      <Text sx={{ fontWeight: "700" }} mt={"20px"}>
                        Qualification
                      </Text>
                      <Text sx={{ fontSize: 1 }}>UG in any Degree</Text>
                      <Text
                        sx={{ fontWeight: "700", fontSize: ["14px", "16px"] }}
                        mt={"20px"}
                      >
                        Exam Pattern
                      </Text>
                      <Text
                        sx={{ fontWeight: "700", fontSize: ["14px", "16px"] }}
                      >
                        Stage I : (Preliminary – Objective Type)
                      </Text>
                      <Text sx={{ fontSize: 1 }}>
                        Subject : General Studies(175)+ Mental Ability(25)
                      </Text>
                      <Text sx={{ fontSize: 1 }}>Questions : 200</Text>
                      <Text sx={{ fontSize: 1 }}>Marks : 300 (3 hours)</Text>
                    </Box>
                    <Box>
                      <Text
                        sx={{ fontWeight: "700", fontSize: ["14px", "16px"] }}
                      >
                        Stage II (Mains - Descriptive)
                      </Text>
                      <Text sx={{ fontSize: 1 }}>Subject : Paper I </Text>
                      <Text sx={{ fontSize: 1 }}>Marks : 300</Text>
                      <Text sx={{ fontSize: 1 }}>Time : 3 hours</Text>
                      <Text
                        mt={"20px"}
                        sx={{ fontWeight: "700", fontSize: ["14px", "16px"] }}
                      >
                        Stage III (Interview)
                      </Text>
                      <Text sx={{ fontSize: 1 }}>Subject : Oral Test</Text>
                      <Text sx={{ fontSize: 1 }}>Marks : 40</Text>
                    </Box>
                  </Grid>
                </Box>
              </Box>
            </Box>

            <Box mt={"40px"}>
              <Text
                mb={"10px"}
                sx={{ fontWeight: "800", fontSize: ["14px", "16px"] }}
              >
                GROUP-II/IIA
              </Text>
              <Box
                sx={{
                  width: ["300px", "90%", "90%", "400px", "500px"],
                  border: "1px solid black",
                }}
              >
                <Box
                  sx={{
                    height: "100px",
                    background: "#9D1210",
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Text sx={{ color: "white", fontWeight: "800" }}>
                    Sub Registrar, PO, JEO and all Other Posts
                  </Text>
                </Box>
                <Box my={"30px"} mb={"164px"} mx={"30px"}>
                  <Grid columns={2}>
                    <Box>
                      <Text
                        sx={{ fontWeight: "700", fontSize: ["14px", "16px"] }}
                      >
                        Eligibility
                      </Text>
                      <Text
                        sx={{ fontWeight: "700", fontSize: ["14px", "16px"] }}
                        mt={"20px"}
                      >
                        Age
                      </Text>
                      <Text
                        sx={{ fontWeight: "700", fontSize: ["14px", "16px"] }}
                        mt={"20px"}
                      >
                        Maximum Age (Others) :
                      </Text>
                      <Text sx={{ fontSize: 1 }}>No maximum age limit</Text>
                      <Text
                        sx={{ fontWeight: "700", fontSize: ["14px", "16px"] }}
                        mt={"20px"}
                      >
                        Qualification
                      </Text>
                      <Text sx={{ fontSize: 1 }}>UG in any Degree</Text>
                      <Text
                        sx={{ fontWeight: "700", fontSize: ["14px", "16px"] }}
                        mt={"20px"}
                      >
                        Exam Pattern
                      </Text>
                      <Text
                        sx={{ fontWeight: "700", fontSize: ["14px", "16px"] }}
                      >
                        Stage I : (Preliminary – Objective Type)
                      </Text>
                      <Text sx={{ fontSize: 1 }}>
                        Subject : General Studies(175)+ Mental Ability(25)
                      </Text>
                      <Text sx={{ fontSize: 1 }}>Questions : 200</Text>
                      <Text sx={{ fontSize: 1 }}>Marks : 300 (3 hours)</Text>
                    </Box>
                    <Box>
                      <Text
                        sx={{ fontWeight: "700", fontSize: ["14px", "16px"] }}
                      >
                        Stage II (Mains - Descriptive)
                      </Text>
                      <Text sx={{ fontSize: 1 }}>
                        Subject : Paper I (Tamil Eligibility Test)
                      </Text>
                      <Text sx={{ fontSize: 1 }}>Marks : 100</Text>
                      <Text sx={{ fontSize: 1 }}>Time : 3 hours (each)</Text>
                      <Text
                        mt={"20px"}
                        sx={{ fontWeight: "700", fontSize: ["14px", "16px"] }}
                      >
                        Stage III (Interview)
                      </Text>
                      <Text sx={{ fontSize: 1 }}>Subject : Oral-Personal </Text>
                      <Text sx={{ fontSize: 1 }}>Marks : 40</Text>
                    </Box>
                  </Grid>
                </Box>
              </Box>
            </Box>

            <Box mt={"40px"}>
              <Text mb={"10px"} sx={{ fontWeight: "800" }}>
                GROUP II
              </Text>
              <Box
                sx={{
                  width: ["300px", "90%", "90%", "400px", "500px"],
                  border: "1px solid black",
                }}
              >
                <Box
                  sx={{
                    height: "100px",
                    background: "#9D1210",
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Text sx={{ color: "white", fontWeight: "800" }}>
                    Asst. Personal Clerks in various departments
                  </Text>
                </Box>
                <Box my={"30px"} mx={"30px"}>
                  <Grid columns={2}>
                    <Box>
                      <Text
                        sx={{ fontWeight: "700", fontSize: ["14px", "16px"] }}
                      >
                        Eligibility
                      </Text>
                      <Text
                        sx={{ fontWeight: "700", fontSize: ["14px", "16px"] }}
                        mt={"20px"}
                      >
                        Age
                      </Text>
                      <Text
                        sx={{ fontWeight: "700", fontSize: ["14px", "16px"] }}
                        mt={"20px"}
                      >
                        Minimum age
                      </Text>
                      <Text sx={{ fontSize: 1 }}>18/20 Years</Text>
                      <Text
                        sx={{ fontWeight: "700", fontSize: ["14px", "16px"] }}
                        mt={"20px"}
                      >
                        Maximum Age (OC) :
                      </Text>
                      <Text sx={{ fontSize: 1 }}>30 Years</Text>
                      <Text
                        sx={{ fontWeight: "700", fontSize: ["14px", "16px"] }}
                        mt={"20px"}
                      >
                        Maximum Age (Others) :
                      </Text>
                      <Text sx={{ fontSize: 1 }}>No maximum age limit</Text>
                      <Text
                        sx={{ fontWeight: "700", fontSize: ["14px", "16px"] }}
                        mt={"20px"}
                      >
                        Qualification
                      </Text>
                      <Text sx={{ fontSize: 1 }}>UG in any Degree</Text>
                      <Text
                        sx={{ fontWeight: "700", fontSize: ["14px", "16px"] }}
                        mt={"20px"}
                      >
                        Exam Pattern
                      </Text>
                      <Text
                        sx={{ fontWeight: "700", fontSize: ["14px", "16px"] }}
                      >
                        Stage I : (Preliminary – Objective Type)
                      </Text>
                      <Text sx={{ fontSize: 1 }}>
                        Subject : General Studies(175)+ General Tamil / English
                      </Text>
                      <Text sx={{ fontSize: 1 }}>Questions : 200</Text>
                      <Text sx={{ fontSize: 1 }}>Marks : 300 (3 hours)</Text>
                    </Box>
                    <Box>
                      <Text
                        sx={{ fontWeight: "700", fontSize: ["14px", "16px"] }}
                      >
                        Stage II (Mains - Descriptive)
                      </Text>
                      <Text sx={{ fontSize: 1 }}>Subject : Paper I</Text>
                      <Text sx={{ fontSize: 1 }}>Marks : 100</Text>
                      <Text sx={{ fontSize: 1 }}>Time : 3 hours </Text>
                      <Text
                        mt={"20px"}
                        sx={{ fontWeight: "700", fontSize: ["14px", "16px"] }}
                      >
                        (No Interview)
                      </Text>
                    </Box>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Box>
        <Box mt={"100px"}>
          <Text
            sx={{
              fontWeight: "900",
              fontSize: [0, 1],
            }}
            style={{
              border: "1px solid black",
              padding: "14px",
              textAlign: "center",
              width: "100%",
            }}
          >
            CURRENT AFFAIRS PROGRAMME
          </Text>
          <Text
            sx={{ fontWeight: "900", fontSize: [0, 1] }}
            style={{
              border: "1px solid black",
              padding: mobile ? "8px" : "14px",
              textAlign: "center",
              width: "100%",
              background: "#890101",
              color: "white",
            }}
          >
            Holistic Aproach Towards Enrichments of Knowledge,concepts and
            Critical Thinking
          </Text>
          <table style={{ borderCollapse: "collapse", width: "100%" }}>
            <tbody>
              <tr style={{ border: "1px solid black" }}>
                <td
                  style={{
                    border: "1px solid black",
                    padding: mobile ? "10px" : "18px",
                    textAlign: "left",
                  }}
                >
                  <Text sx={{ fontWeight: "700", fontSize: [0, 1] }}>
                    Last Week of December
                  </Text>
                  <Text sx={{ marginTop: "10px", fontSize: [0, 1] }}>
                    days:
                  </Text>
                  <Text sx={{ fontSize: [0, 1] }}>timing:</Text>
                </td>
                <td
                  style={{
                    border: "1px solid black",
                    padding: mobile ? "10px" : "18px",
                    textAlign: "left",
                  }}
                >
                  <Text sx={{ fontSize: [0, 1] }}> fees:</Text>
                </td>
                <td
                  style={{
                    border: "1px solid black",
                    padding: mobile ? "10px" : "18px",
                    textAlign: "left",
                  }}
                >
                  <Flex sx={{ gap: "20px", flexDirection: ["column", "row"] }}>
                    <Text sx={{ fontWeight: "900", fontSize: [0, 1] }}>
                      Schedule
                    </Text>
                    <Button
                      sx={{
                        borderRadius: "25px",
                        marginTop: ["-10px"],
                        maxWidth: ["80px", "150px"],
                        fontSize: [0, 1],
                        cursor: "pointer",
                      }}
                    >
                      Click here
                    </Button>
                  </Flex>
                </td>
              </tr>
            </tbody>
          </table>

          <Box my={["40px", "80px"]}>
            <Text
              sx={{
                textDecoration: "underline",
                fontWeight: "500",
                fontSize: ["18px", "24px"],
                textAlign: "center",
              }}
            >
              Current Affairs Programme
            </Text>
            <Grid my={[0, "30px"]} mx={[0, "4%"]} columns={[1, 2, 2, 2, 2]}>
              <Box sx={{ fontSize: [0, 1, 1, "16px"] }}>
                <ul>
                  <li>
                    Classes by Mr. Nikhil Sir, Senior Faculty (IIT’N AND GOLD
                    MEDALIST)(who himself writes for THE HINDU, THE INDIAN
                    EXPRESS & other National, International Publications) &
                    Others, having a special grasp on National & International
                    Affairs
                  </li>
                  <li>
                    comprehensive coverage of Weekly & Monthly current Affairs
                  </li>
                  <li>
                    comprehensive coverage of Weekly & Monthly current Affairs
                  </li>
                  <li>Live-Online batches</li>
                  <li>
                    1. Audio-visuals, PowerPoint presentations and other
                    interactive modes of learning platforms are used to ensure
                    students find learning interesting and engaging.
                  </li>
                </ul>
              </Box>
              <Box sx={{ fontSize: [0, 1, 1, "16px"] }}>
                <ul style={{ listStyle: "none" }}>
                  <li>
                    Notes: Simplified & Detailed covering Micro and Macro Issues
                  </li>
                  <li>Answer Writing Sessions</li>
                  <li>Monthly Test.</li>
                  <li>Art of Notes Making</li>
                  <li>Yojana and Kurukshetra Summary.</li>
                </ul>
              </Box>
            </Grid>
          </Box>
        </Box>
        <Box mx={"6%"}>
          <CustomHeadingText label={"About the Exam"} />
          <CustomHeadingText
            label={`Group wise Eligibility, Exam Pattern and Syllabus`}
          />
          <CustomContentText label={"prelims Syllabus"} />
          <CustomContentText
            label={`
TNPSC Gr I, Gr II and Gr II A Preliminary Examination – Questions will be asked from the following topics*`}
          />
          <CustomContentText
            label={`
General Science | Current events | Geography | History and Culture of India | Indian Polity | Indian Economy | Indian National Movement | Aptitude and Mental AbilityLanguage: General Tamil (or) General English (Only for Gr II, Gr II A).
`}
          />
          <CustomContentText
            sx={{ marginTop: "20px" }}
            label={`* For detailed syllabus refer : English Syllabus | Tamil Syllabus`}
          />
        </Box>
        <Box mt={100}>
          <Grid columns={[1, 1, 1, 1, 2]} sx={{ placeItems: ["center"] }}>
            <Box
              sx={{
                border: "1px solid black",
                width: ["92%", "92%", "90%", "90%", "540px"],
              }}
            >
              <Box sx={{ borderBottom: "1px solid black" }}>
                <Flex
                  sx={{
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "80px",
                  }}
                >
                  <Text sx={{ fontWeight: "800", fontSize: ["16px", "24px"] }}>
                    Gr I – Mains Syllabus
                  </Text>
                </Flex>
              </Box>
              <Box px={[30, 30, 30, 60, 60]} py={50}>
                <Box>
                  <Text sx={{ fontSize: ["14px", "18px"], fontWeight: "800" }}>
                    PAPER – I / Unit I
                  </Text>
                  <Box mt={14}>
                    <Flex
                      sx={{
                        flexDirection: "column",
                        gap: "10px",
                        justifyContent: "center",
                        alignItems: "flexStart",
                        fontSize: 1,
                      }}
                    >
                      <li>TNPSC Gr I Main Examination*</li>
                      <li>Modern History of India and Indian Culture</li>
                      <li>General Mental Ability</li>
                      <li>
                        Role and Impact of Science and Technology in the
                        development of India and Tamil Nadu
                      </li>
                    </Flex>
                  </Box>
                </Box>
                <Box mt={20}>
                  <Text sx={{ fontSize: ["14px", "18px"], fontWeight: "800" }}>
                    PAPER – II / Unit II and III
                  </Text>
                  <Box mt={14}>
                    <Flex
                      sx={{
                        flexDirection: "column",
                        gap: "10px",
                        justifyContent: "center",
                        alignItems: "flexStart",
                        fontSize: 1,
                      }}
                    >
                      <li>
                        Indian Polity and emerging political trends across the
                        world affecting India and Geography of India
                      </li>
                      <li>
                        Tamil language, Tamil society–its culture and heritage /
                        English language, English language skills
                      </li>
                      <li>
                        Administration of Union and States with special
                        reference to Tamil Nadu
                      </li>
                    </Flex>
                  </Box>
                </Box>
                <Box mt={20}>
                  <Text sx={{ fontSize: ["14px", "18px"], fontWeight: "800" }}>
                    PAPER – III / Unit IV and V
                  </Text>
                  <Box mt={14} sx={{ fontSize: 1 }}>
                    <li>
                      Current events of national and international importance
                    </li>
                    <li>
                      Current economic trends; Indian economy and impact of
                      global economy on India
                    </li>
                    <li>Socio-economic issues in India and Tamil Nadu</li>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                border: "1px solid black",
                width: ["92%", "92%", "90%", "90%", "540px"],
              }}
            >
              <Box sx={{ borderBottom: "1px solid black" }}>
                <Flex
                  sx={{
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "80px",
                  }}
                >
                  <Text sx={{ fontWeight: "800", fontSize: ["16px", "24px"] }}>
                    Gr II – Mains Syllabus
                  </Text>
                </Flex>
              </Box>
              <Box px={[30, 30, 30, 60, 60]} pt={50} pb={110}>
                <Box>
                  <Text sx={{ fontSize: ["14px", "22px"], fontWeight: "800" }}>
                    PAPER – I / Unit I
                  </Text>
                  <Box mt={30}>
                    <Flex
                      sx={{
                        flexDirection: "column",
                        gap: "10px",
                        justifyContent: "center",
                        alignItems: "flexStart",
                        fontSize: [1, "16px"],
                      }}
                    >
                      <li>TNPSC Gr II (Interview Post) Main Examination</li>
                      <li>
                        Role and Impact of Science and Technology in the
                        development of India and Tamil Nadu
                      </li>
                    </Flex>
                  </Box>
                </Box>
                <Box mt={24}>
                  <Text sx={{ fontSize: ["14px", "22px"], fontWeight: "800" }}>
                    PAPER – II / Unit II and III
                  </Text>
                  <Box mt={30}>
                    <Flex
                      sx={{
                        flexDirection: "column",
                        gap: "10px",
                        justifyContent: "center",
                        alignItems: "flexStart",
                        fontSize: [1, "16px"],
                      }}
                    >
                      <li>
                        Administration of Union and States with special
                        reference to Tamil Nadu
                      </li>
                      <li>Socio-economic issues in India and Tamil Nadu</li>
                    </Flex>
                  </Box>
                </Box>
                <Box mt={40}>
                  <Text sx={{ fontSize: ["14px", "22px"], fontWeight: "800" }}>
                    PAPER – III / Unit IV and V
                  </Text>
                  <Box mt={30} sx={{ fontSize: [1, "16px"] }}>
                    <li>Current Issues at National Level</li>
                    <li>Current Issues at State Level</li>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Box>
        <ContactSection />
      </Layout>
    </Box>
  )
}

export default TnpscPage
